import React, { FC } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ComponentWow from "../../Wow";

interface IProductDetail {
  image: string;
  altImages: string;
  productName: string;
  productDesc: string;
  size: string;
  spec: string;
  characteristic: string;
  packaging: string;
  tags?: string[];
}

const ProductDetail: FC<IProductDetail> = ({
  image,
  altImages,
  productName,
  productDesc,
  size,
  spec,
  characteristic,
  packaging,
  tags,
}) => {
  const { t } = useTranslation();

  const renderTags = () => {
    if (tags && tags.length > 0) {
      return tags.map((val, index) => {
        return (
          <span
            className="badge badge-pill badge-primary tag-text"
            key={`tags-detail-${index}`}
          >
            {t(`dataTags.${val}`)}
          </span>
        );
      });
    }
  };

  return (
    <ComponentWow>
      <div className="container-fluid py-5">
        <div className="row px-xl-5">
          <div className="col-lg-5 pb-5">
            <div
              id="product-carousel"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner border">
                <div className="carousel-item active">
                  <img className="w-100 Sirv" data-src={image} alt={altImages} />
                </div>
              </div>
              {/* <a
                className="carousel-control-prev"
                href="#product-carousel"
                data-slide="prev"
              >
                <i className="fa fa-2x fa-angle-left text-dark"></i>
              </a>
              <a
                className="carousel-control-next"
                href="#product-carousel"
                data-slide="next"
              >
                <i className="fa fa-2x fa-angle-right text-dark"></i>
              </a> */}
            </div>
          </div>

          <div className="col-lg-7 pb-5">
            <h3 className="font-weight-semi-bold">{productName}</h3>

            <div className="mb-4 mt-3">
              <div dangerouslySetInnerHTML={{ __html: productDesc }} />
            </div>

            {/* <div className="d-flex pt-2">
              <p className="text-dark font-weight-medium mb-0 mr-2">
                {t("detail.shareOn")}:
              </p>
              <div className="d-inline-flex">
                <a className="text-dark px-2" href="">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="text-dark px-2" href="">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="text-dark px-2" href="">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a className="text-dark px-2" href="">
                  <i className="fab fa-pinterest"></i>
                </a>
              </div>
            </div> */}
             <div className="mb-3">
              <a
                href=""
                className="btn btn-primary mt-3 py-2 px-4"
                data-toggle="modal"
                data-target="#getAQuote"
              >
                {t("getAQuote")}
              </a>
            </div>
            <div className="pt-2">
              <p className="text-dark font-weight-medium mb-0 mr-2">
                {t("detail.tags")}:
              </p>
              {renderTags()}
            </div>
           
          </div>
        </div>
        <div className="row px-xl-5">
          <div className="col">
            <div className="nav nav-tabs justify-content-center border-secondary mb-4 detail-tabs">
              <a
                className="nav-item nav-link active"
                data-toggle="tab"
                href="#size"
              >
                {t("detail.size")}
              </a>
              <a
                className="nav-item nav-link"
                data-toggle="tab"
                href="#specification"
              >
                {t("detail.specification")}
              </a>
              <a
                className="nav-item nav-link"
                data-toggle="tab"
                href="#characteristic"
              >
                {t("detail.characteristic")}
              </a>
              <a
                className="nav-item nav-link"
                data-toggle="tab"
                href="#packaging"
              >
                {t("detail.packaging")}
              </a>
            </div>

            <div className="tab-content">
              <div className="tab-pane fade show active" id="size">
                <div dangerouslySetInnerHTML={{ __html: size }} />
              </div>
              <div className="tab-pane fade" id="specification">
                <div dangerouslySetInnerHTML={{ __html: spec }} />
              </div>
              <div className="tab-pane fade" id="characteristic">
                <div dangerouslySetInnerHTML={{ __html: characteristic }} />
              </div>
              <div className="tab-pane fade" id="packaging">
                <div dangerouslySetInnerHTML={{ __html: packaging }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ComponentWow>
  );
};

export default ProductDetail;
